import * as React from "react";
import Layout from "../components/layout";
import Top10Posts from "../components/top10Posts";

const NotFoundPage = () => {
  return (
    <Layout pageTitle="404 Not Found">
      <div className="pt-3 pb-3">
        <blockquote className="blockquote">
          Not sure what you are looking for but it is not here!!! Yet it might
          be in one of my blog posts below.
        </blockquote>
      </div>
      <Top10Posts />
    </Layout>
  );
};

export default NotFoundPage;
